import React from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { Box, Link, Typography } from "@mui/material";
import { info } from "../../info/Info";

export default function Home() {

   return (
      <Box component={'main'} display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={'center'}
         justifyContent={'center'} minHeight={'calc(100vh - 175px)'}>
         <Box className={classNames(Style.avatar, Style.shadowed)} alt={'image of developer'} style={{ background: info.gradient }} component={'img'} src={me} width={{ xs: '35vh', md: '40vh' }}
            height={{ xs: '35vh', md: '40vh' }}
            borderRadius={'50%'} p={'0.75rem'} mb={{ xs: '1rem', sm: 0 }} mr={{ xs: 0, md: '2rem' }} />
         <Box>
            <h1 style={{padding: '0 1rem'}}>Hello, I'm <span style={{ background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{info.firstName} </span><span className={Style.hand}>🤚</span>
            </h1>
            <h2 style={{padding: '0 1rem'}}>Glad to see you here</h2>
            {/* <h2>{info.position}</h2> */}
            <Typography padding={'1rem 1rem 0'} fontSize={'1.2rem'} lineHeight={'1.8rem'} width={'100%'} maxWidth={'400px'}>
               I'm inviting you to see what I'm doing as the <Link href="https://www.permit.io/author/gemanor" target={'_blank'} rel={'noopener noreferrer'} underline="none">Director of DevRel @ Permit.io</Link>, read some of my <Link href="https://linktr.ee/gemanor" underline="none" target={'_blank'} rel={'noopener noreferrer'}>technical articles</Link>, watch some of my <Link href="https://www.youtube.com/playlist?list=PLt_dDry66sLxvPjidOxhyLpZJx4Me4Kol" target={'_blank'} rel={'noopener noreferrer'} underline="none">recorded tech talks</Link>, stalk my restless <Link href="https://my.flightradar24.com/gemanor" underline="none">travel log</Link>, or just wash your eyes with some of <Link target={'_blank'} rel={'noopener noreferrer'} href="https://unsplash.com/@gmanor" underline="none">my camera creations</Link>.

            </Typography>
            {/* <Typography fontSize={'1.2rem'} lineHeight={'1.8rem'} width={'100%'} maxWidth={'400px'}>
               When I'm not crafting DevEx as the <Link href="https://www.permit.io/author/gemanor" target={'_blank'} rel={'noopener noreferrer'} underline="none">Director of DevRel @ Permit.io</Link>, you can find me <Link href="https://linktr.ee/gemanor" underline="none" target={'_blank'} rel={'noopener noreferrer'}>writing somewhere else</Link>, speaking at <Link href="https://www.youtube.com/playlist?list=PLt_dDry66sLxvPjidOxhyLpZJx4Me4Kol" target={'_blank'} rel={'noopener noreferrer'} underline="none">tech conferences</Link>, travel <Link href="https://my.flightradar24.com/gemanor" underline="none">somewhere new</Link>, or painting moments with <Link target={'_blank'} rel={'noopener noreferrer'} href="https://unsplash.com/@gmanor" underline="none">my camera</Link>.
            </Typography> */}
            <Box component={'ul'} p={'0.8rem'} width={'400px'} maxWidth={'100%'}>
               {info.miniBio.map((bio, index) => (
                  <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} link={bio.link} />
               ))}
            </Box>
            <Box display={'flex'} gap={'1.5rem'} justifyContent={'center'} fontSize={{ xs: '2rem', md: '2.5rem' }}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>
         </Box>
      </Box>
   )
}