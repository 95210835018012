import React from 'react';
import { Box, Button } from "@mui/material";
import { BusinessCenter } from '@mui/icons-material';

function EmojiBullet(props) {
    const { emoji, text, link } = props;

    return (
        <Button fullWidth={true} href={link} sx={{justifyContent: 'flex-start', marginBottom: '10px'}} variant='outlined' target={'_blank'} rel={'noopener noreferrer'}>
            <Box component={'li'} fontSize={'1rem'} lineHeight={1.5}>
                <Box component={'span'} aria-label="cheese"
                    role="img"
                    mr={{ xs: '0.5rem', md: '1rem' }} fontSize={'1.5rem'}>{emoji}</Box> {text}
            </Box>
        </Button>
    );
}

export default EmojiBullet;